import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    padding-inline:5px;
`

const Btn = styled.button`
    height: 41px;
    background-color: #fff;
    border: 1px solid #D5DAE1;
    width: 100%;
    max-width:500px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0D2C54;
    cursor: pointer;
    padding-inline:10px;
    font-family: "Roboto", sans-serif;
    @media (max-width: 600.98px) { 
        font-size: 16px;

    }
`

function NavButton({ children, ...rest }) {
    return (
        <Wrapper>
            <Btn {...rest}>{children}</Btn>
        </Wrapper>
    )
}

export default NavButton