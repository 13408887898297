import React from 'react'
import { useMultiStepFormContext } from '../context/MultiStepForm/MultiStepFormContext'
import Step1PersonInfo from '../layouts/Form/Steps/Step1PersonInfo'
import FormLayout from '../layouts/Form/FormLayout'
import Step2Calculation from '../layouts/Form/Steps/Step2Calculation'
import Step3Result from '../layouts/Form/Steps/Step3Result'
// import useWindowDimensions from '../hooks/getWindowDimensions'
// import Navbar from '../layouts/Home/Navbar'

function Form() {
  const { currentStep } = useMultiStepFormContext()
  // const { width } = useWindowDimensions()
  return (
    <>
      <FormLayout>
        {
          {
            1: <Step1PersonInfo />,
            2: <Step2Calculation />,
            3: <Step3Result />,
          }[currentStep]
        }
      </FormLayout>
    </>
  )
}

export default Form