import React from 'react'
import styled from 'styled-components'

const Btn = styled.button`
    height: 60px;
    background-color: #0D2C54;
    border: 1px solid #0D2C54;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    font-family: 'Mulish', sans-serif !important;
    @media (max-width: 600.98px) { 
        font-size: 14px;
         font-weight: 700;

    }
`

function FormButton({ children, ...rest }) {
    return (
        <Btn {...rest}>{children}</Btn>
    )
}

export default FormButton