import { Typography } from '@mui/material'
import React from 'react'

function InfoSecondTitle({ children }) {
    return (
        <Typography variant="h1" paddingBottom={'0.75rem'} color={'#002345'} fontWeight={700} sx={{
            fontSize: {
                xs: '32px',
                sm: '28px',
                md: '32px',
                lg: '40px',
            }
        }}>
            {children}
        </Typography>
    )
}

export default InfoSecondTitle