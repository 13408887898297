import { Box } from '@mui/material'
import React from 'react'
import styled from 'styled-components'


const Wrapper = styled(Box)`
    margin-inline: auto;
    max-width: 1200px;
    width: 100%;
    padding-inline: 1.5rem;
`

function Container({ children, ...rest }) {
    return (
        <Wrapper {...rest}> {children}</Wrapper>
    )
}

export default Container