import React from 'react'
import Container from '../../components/Container'
import { Box, Grid } from '@mui/material'
import HeroSubtitle from '../../components/HeroSubtitle'
import HomeSecondTitle from '../../components/HomeSecondTitle'

import HomeInfo1 from '../../assets/images/HomeInfo1.webp'
import HomeInfo2 from '../../assets/images/HomeInfo2.webp'
import HomeInfo3 from '../../assets/images/HomeInfo3.webp'
import HomeLabel from '../../components/HomeLabel'
import FormButton from '../../components/FormButton'
import InfoSecondTitle from '../../components/InfoSecondTitle'


function Info() {
    return (
        <div style={{ backgroundColor: "#F3F5F7", paddingTop: "100px", paddingBottom: "100px" }}>
            <Container>
                <Box px={{ md: 2 }}>
                    <Grid container justifyContent={'space-around'} flexDirection={{ xs: 'column-reverse', sm: 'row' }} rowGap={6}>
                        <Grid item xs={12} sm={6}>
                            <Grid container columnSpacing={{ xs: 1, sm: 2 }} alignItems={'center'}>
                                <Grid item xs={6}>
                                    <img src={HomeInfo2} style={{ width: '100%', height: 'auto' }} alt="" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Box pb={{ xs: 1, sm: 2 }}>
                                        <img src={HomeInfo1} style={{ width: '100%', height: 'auto' }} alt="" />
                                    </Box>
                                    <Box>
                                        <img src={HomeInfo3} style={{ width: '100%', height: 'auto' }} alt="" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={'auto'}>
                            <Box width={{ sm: 'min-content' }}>
                                <HomeLabel>PRODUCTS</HomeLabel>
                                <InfoSecondTitle>
                                    Anmeldung
                                    Prämienverbilligung
                                    2025
                                </InfoSecondTitle>
                                <HeroSubtitle color={'#7C7C7C'}>
                                    Im September 2024 erfolgt der Hauptversand der Armeidecodes an potenziell anspruchsberechtigte Personen im Karton Aargau, Haben Sie bis Ende September 2024 keinen Anmeldecode per Post erhalten, können Sie ab Oktober 2024 dreit über diese Webseite einen besteten. Weitere Informátionen finden Sie hier:
                                </HeroSubtitle>
                                {/* <Grid container paddingTop={'20px'}>
                                    <FormButton style={{ maxWidth: "209px", borderRadius: '4px', fontWeight: "500", fontSize: "16px" }}>
                                        View our products
                                    </FormButton>
                                </Grid> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

        </div>
    )
}

export default Info