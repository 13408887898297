import React from 'react'
import styled from 'styled-components'
import Arrow from '../assets/svgs/WhiteSelectArrow.svg'

const Input = styled.select`
    width: 100%;
    height: 60px;
    border: 1px solid #F2F2F2;
    color:  #757575;
    font-weight: 500;
    font-family:'Mulish', sans-serif !important;
    background-color: transparent;
    padding-inline: 20px;
    border-radius: 12px;
    outline: none;
    box-sizing: border-box;
    font-size: 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
     background-color: #F2F2F2;
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 50%;
    color:  rgba(13, 44, 84, 1);

    &:invalid  {
        color: #757575 !important;
        font-size: 16px;
    }
    option  { 
        color: #000 !important;
    }
`

function FormSelect({ ...rest }) {
    return (
        <Input
            {...rest}
        />
    )
}

export default FormSelect