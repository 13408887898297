import { Typography } from '@mui/material'
import React from 'react'

function HomeLabel({ children }) {
    return (
        <Typography variant="h1" paddingBottom={'0.75rem'} color={'#0075C9'} fontWeight={700} sx={{
            fontSize: {
                xs: '14px',
                sm: '14px',
                md: '14px',
                lg: '14px',
            },
            lineHeight:{
                xs: '12px',
                sm: '12px',
                md: '16px',
                lg: '16px',
            }
        }}>
            {children}
        </Typography>
    )
}

export default HomeLabel