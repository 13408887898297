import React from 'react'
import styled from 'styled-components'
import Arrow from '../assets/svgs/BlackSelectArrow.svg'

const Input = styled.select`
    width: 100%;
    height: 60px;
    border: 1px solid #E4E4E4;
    font-weight: 400;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 40px;
    border-radius: 8px;
    outline: none;
    box-sizing: border-box;
    font-size: 16px;
    font-family:'Mulish', sans-serif;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #F2F2F2;
    background-image: url(${Arrow});
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 50%;
    color: #000;

    &:invalid  {
        color: #757575 !important;
        font-size: 16px;
    }
    option {
        color: black !important;
    }
`

function WhiteFormSelect({ ...rest }) {
    return (
        <>
            <Input
                {...rest}
            />
        </>
    )
}

export default WhiteFormSelect