import { Box, Step, StepConnector, StepLabel, Stepper, stepConnectorClasses } from '@mui/material'
import React from 'react'
import styled from 'styled-components';
import { useMultiStepFormContext } from '../../context/MultiStepForm/MultiStepFormContext';
import { ReactComponent as ActiveStep } from '../../assets/svgs/ActiveStep.svg'
import { ReactComponent as UnActiveStep } from '../../assets/svgs/UnActiveStep.svg'
import Container from '../../components/Container';
import useWindowDimensions from '../../hooks/getWindowDimensions';

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
    left: '-50%',
    right: '50%',
    color: '#95A2B4',
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: 0,
    backgroundColor: '#95A2B4',
    borderRadius: 1,
  }
}));

const QontoStepIconRoot = styled('div')(({ $owner_state }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  [`&.MuiStepLabel-label`]: {
    color: '#fff !important'
  },
  svg: {
    zIndex: 2,
  },
  '@media (max-width: 600.98px)': {
    svg: {
      width: $owner_state.active ? 29 : 21,
    },
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot $owner_state={{ completed, active }} className={className}>
      {active ? (
        <ActiveStep />
      ) : (
        <UnActiveStep />
      )}
    </QontoStepIconRoot>
  );
}

const CustomStepLabel = styled(StepLabel)`
  .MuiStepLabel-label {
    color: #0D2C54 !important;
    margin-top: 30px !important;
    font-size: 18px !important;
  }

  .MuiStepLabel-label.Mui-active {
   font-weight: 700 !important;
  }
  @media (max-width: 600.98px) {
    .MuiStepLabel-label {
      font-size: 12px !important;
      margin-top: 11px !important;
    }
  }
`

const steps = ['Personen', 'Berechnungsgrundlage', 'Ergebnis'];

function FormLayout({ children }) {

  const { currentStep } = useMultiStepFormContext()
  const { width } = useWindowDimensions()

  return (
    <Box maxWidth={'800px'} marginInline={'auto'}>
      <Box pt={10}>
        <Stepper alternativeLabel activeStep={currentStep - 1} connector={<ColorlibConnector />} >
          {steps.map((label) => (
            <Step key={label}>
              <CustomStepLabel StepIconComponent={ColorlibStepIcon}>{label}</CustomStepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Container>
        <Box my={{ xs: 5, md: 10 }}
          boxShadow={'0px 4px 15.2px 0px #8E8E8E40'}
          borderRadius={'8px'}
          paddingBlock={{ xs: '30px', md: '45px' }}
          paddingInline={{ xs: '25px', md: '75px' }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  )
}

export default FormLayout