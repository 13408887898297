import { useState } from "react";
import { MultiStepFormContext } from "./MultiStepFormContext";


const MultiStepFormProvider = ({ children }) => {

  const [currentStep, setCurrentStep] = useState(1);

  const initialFormData = {
    fullName: '',
    tel: '',
    krankenkasse: '',
    ort: '',
  };
  
  const [formData, setFormData] = useState(initialFormData);

  const nextFormStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const setFormStep = (step) => {
    setCurrentStep(step);
  };

  const prevFormStep = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearFormData = () => {
    setFormData(initialFormData);
  };

  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };



  const ContextValues = {
    nextFormStep,
    setFormStep,
    prevFormStep,
    currentStep,
    updateFormData,
    formData,
    setCurrentStep,
    clearFormData,
    handleInputChange,
  };

  return (
    <MultiStepFormContext.Provider value={ContextValues}>
      {children}
    </MultiStepFormContext.Provider>
  );
};

export default MultiStepFormProvider;
