import React from 'react'
import WhiteFormSelect from '../../../components/WhiteFormSelect'
import WhiteFormLabel from '../../../components/WhiteFormLabel'
import WhiteFormSmallTitle from '../../../components/WhiteFormSmallTitle'
import { Grid } from '@mui/material'
import FormButton from '../../../components/FormButton'
import { useMultiStepFormContext } from '../../../context/MultiStepForm/MultiStepFormContext'

function Step1PersonInfo() {

    const { formData, handleInputChange, nextFormStep } = useMultiStepFormContext()

    const handleSubmit = (e) => {

        e.preventDefault()
        nextFormStep()
    }


    return (
        <form style={{ marginBottom: '0px' }} onSubmit={handleSubmit}>
            <WhiteFormSmallTitle>*Pfilchtfeld</WhiteFormSmallTitle>
            <Grid container rowSpacing={1} flexDirection={'column'}>
                <Grid item>
                    <WhiteFormLabel>Personen im Haushalt</WhiteFormLabel>
                    <WhiteFormSelect placeholder={'Antragsteller *'} required value={formData.Antragsteller || ""} name='Antragsteller' onChange={e => { handleInputChange(e) }}>
                        <option value="" disabled hidden>Antragsteller *</option>
                        <option value="Erwachsen (ab Jg 1998)">
                            Erwachsen (ab Jg 1998)
                        </option>
                        <option value="junge( r ) Erwachsene( r ) (Jg 1999 - 2005)">
                            junge( r ) Erwachsene( r ) (Jg 1999 - 2005)
                        </option>

                    </WhiteFormSelect>
                </Grid>
                <Grid item mb={4}>
                    <WhiteFormSelect required value={formData.Konkubinats || ""} name='Konkubinats' onChange={e => { handleInputChange(e) }}>
                        <option value="" disabled hidden>Konkubinats-/Ehepartner/in? (Nein)</option>
                        <option value="Nein" >Konkubinats-/Ehepartner/in? (Nein)</option>
                        <option value="Erwachsen (ab Jg 1998)">Erwachsen (ab Jg 1998)</option>
                        <option value="junge (r) Erwach-sene (r) (Jg 1999 - 2005)">junge (r) Erwach-sene (r) (Jg 1999 - 2005)</option>


                    </WhiteFormSelect>
                </Grid>
                <Grid item>

                    <WhiteFormLabel>Kinder</WhiteFormLabel>
                    <WhiteFormSelect required value={formData.AnzahlKinder || ""} name='AnzahlKinder' onChange={e => { handleInputChange(e) }}>
                        <option value="" disabled hidden>Anzahl Kinder (Jg 2006 - 2023)</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </WhiteFormSelect>
                </Grid>
                <Grid item>
                    <WhiteFormSelect required value={formData.AnzahlJunge || ""} name='AnzahlJunge' onChange={e => { handleInputChange(e) }}>
                        <option value="" disabled hidden>Anzahl Junge Erwachsene (1999 - 2005)</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </WhiteFormSelect>
                </Grid>
                <Grid item mt={{ xs: 2, md: 5 }}>
                    <FormButton>
                        WEITER
                    </FormButton>
                </Grid>
            </Grid>
        </form>
    )
}

export default Step1PersonInfo