import React from 'react'
import { useMultiStepFormContext } from '../../../context/MultiStepForm/MultiStepFormContext'
import { Box, Grid, Typography } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import WhiteFormLabel from '../../../components/WhiteFormLabel'
import WhiteFormSmallTitle from '../../../components/WhiteFormSmallTitle'
import FormButton from '../../../components/FormButton'

import { ReactComponent as TooltipIcon } from '../../../assets/svgs/Tooltip.svg'
import { ReactComponent as GreenCheckmark } from '../../../assets/svgs/GreenCheckmark.svg'
import styled from 'styled-components';




const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: '#000',
        maxWidth: 200,
        fontSize: 10,
        fontWeight: 400,
        padding: 10,
        border: 'none',
        boxShadow: '0px 4px 15.2px 0px #8E8E8E40',
        lineHeight: '15px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        fontSize: 20,
        "&:before": {
            border: "2px solid #cccccc20"
        },
        color: '#fff',
    },
}));

function Step3Result() {

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const { formData, nextFormStep } = useMultiStepFormContext()

    const handleSubmit = (e) => {

        e.preventDefault()
        nextFormStep()
    }

    return (


        <Grid container rowSpacing={1} flexDirection={'column'}>
            <Grid item mb={4}>
                <WhiteFormLabel style={{ color: "#3A546C", fontWeight: "800", maxWidth: "250px" }}>Ergebnis der provisorischen Berechnung</WhiteFormLabel>
            </Grid>


            <Grid container alignItems={'center'} rowGap={1}>
                <Grid item xs>
                    <WhiteFormSmallTitle color={'#545454'} style={{ fontWeight: "700" }}>
                        Total Einkommen
                    </WhiteFormSmallTitle>
                </Grid>
                <Grid item xs={'auto'}>
                    <WhiteFormSmallTitle color={'#545454'}>
                        {
                            ((formData.Satz1 || 0)
                                + (formData.Kleinverdienerabzug || 0)
                                + (formData.Liegenschaftsunterhaltskosten || 0)
                                + (formData.Säule3a || 0)
                                + (formData.Freiwillige || 0)
                                + (formData.Satz2 * 0.2 || 0)).toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })
                        }
                    </WhiteFormSmallTitle>
                </Grid>
            </Grid>
            <Box width={'100%'} height={'1px'} bgcolor={'#E4E4E4'} mb={3} />
            <Grid container alignItems={'center'} rowGap={1} columnGap={0.5}>
                <Grid item xs>
                    <Grid container columnGap={0.5}>
                        <Grid item xs={'auto'}>
                            <WhiteFormSmallTitle style={{ fontWeight: "300", color: "#333E48" }}>
                                Einkommensobergrenze
                            </WhiteFormSmallTitle>
                        </Grid>
                        <Grid item pt={{ xs: 0.2, md: 0.5 }}>
                            <Grid item xs='auto'>


                                <HtmlTooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Ab diesem Einkommen besteht kein Anspruch auf Prämienverbilligung für das Jahr 2024. Die gesetzlichen Pauschalabzüge werden bei der Berechnung der Obergrenze berücksichtigt.">
                                    <TooltipIcon onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose} />
                                </HtmlTooltip>

                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={'auto'} mb={0.5}>
                    <WhiteFormSmallTitle color={'#545454'}>
                        {((formData.Satz2 * 0.2 || 0)).toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })}
                    </WhiteFormSmallTitle>
                </Grid>

            </Grid>
            <Box width={'100%'} height={'1px'} bgcolor={'#E4E4E4'} mb={3} />
            <Grid container alignItems={'center'} rowGap={1} columnGap={0.5}>
                <Grid item xs>
                    <WhiteFormSmallTitle style={{ fontWeight: "300", color: "#333E48", maxWidth: "200px" }}>
                        Habe ich möglicherweise
                        Anspruch?
                    </WhiteFormSmallTitle>
                </Grid>
                <Grid item xs={'auto'} mb={'8px'}>
                    <WhiteFormLabel color={'#0D834A'}>
                        JA*
                    </WhiteFormLabel>
                </Grid>
                <Grid item xs={'auto'} mb={'8px'}>
                    <WhiteFormLabel color={'#545454'}>
                        <GreenCheckmark />
                    </WhiteFormLabel>
                </Grid>
            </Grid>
            <Box width={'100%'} height={'1px'} bgcolor={'#E4E4E4'} />
            <Grid item mt={4}>
                <Typography color="#7D8592" fontWeight={700} fontSize={24} mb={1}>
                    Bitte beachten Sie:
                </Typography>
                <WhiteFormSmallTitle color="#545454" lineHeight="23px">
                    Sollte sich Ihre finanzielle und/oder persönliche Situation seit dem Jahr 2021 verändert haben, ist nicht die Steuerveranlagung 2021 massgebend. In diesem Fall können Sie sich nicht auf die Anspruchsprüfung des Online-Rechners stützen.
                </WhiteFormSmallTitle>
                <Box mt={4} mb={4}>
                    <a style={{ textDecoration: 'none' }}
                        href="https://www.sva-ag.ch/private/ihre-private-situation/finanzielle-unterstutzung/pramienverbilligung/meldung-von"
                        target='_blank' rel="noreferrer">
                        <WhiteFormSmallTitle color="#0D2C54" lineHeight="23px">
                            Mehr zum Thema Einkommensveränderungen
                        </WhiteFormSmallTitle>
                    </a>
                </Box>
                <WhiteFormSmallTitle color="#545454" lineHeight="23px">
                    * <Typography variant='span' fontWeight={700}>Hinweis</Typography>: Die Berechnungen geben nur einen allgemeinen Anhaltspunkt und sind nicht verbindlich. Das Ausfüllen des Online-Rechners gilt nicht als Antrag.
                </WhiteFormSmallTitle>
            </Grid>
            <Grid item>

                <Grid item mt={{ xs: 2, md: 5 }}>
                    <FormButton>
                        ZUR STARTSEITE PRÄMIENVERBILLIGUNG
                    </FormButton>
                </Grid>
            </Grid>
        </Grid>

    )
}

export default Step3Result