import React from 'react'
import FormButton from '../../../components/FormButton'
import { Box, Grid, Typography } from '@mui/material'
import WhiteFormSmallTitle from '../../../components/WhiteFormSmallTitle'
import WhiteFormLabel from '../../../components/WhiteFormLabel'
import { ReactComponent as File } from '../../../assets/svgs/DownloadFile.svg'
import WhiteFormInput from '../../../components/WhiteFormInput'
import { useMultiStepFormContext } from '../../../context/MultiStepForm/MultiStepFormContext'
import { NumericFormat } from 'react-number-format'

function Step2Calculation() {

    const { formData, updateFormData, nextFormStep } = useMultiStepFormContext()

    const handleSubmit = (e) => {

        e.preventDefault()
        nextFormStep()
    }
    
    return (
        <form style={{ marginBottom: '0px' }} onSubmit={handleSubmit}>
            <WhiteFormSmallTitle>*Pfilchtfeld</WhiteFormSmallTitle>
            <Grid container rowSpacing={1} flexDirection={'column'}>
                <Grid item>
                    <WhiteFormLabel style={{ color: "#3A546C", fontWeight: "800" }}>Berechnungsgrundlage: Ihre definitive
                        Steuerveranlagung 2021</WhiteFormLabel>
                    <WhiteFormSmallTitle style={{ color: "#333E48", fontWeight: "300" }}>
                        Bei mehr als einer steuerpflichtigen Person müssen die Zahlen aller Steuerveranlagungen 2021 zusammengezählt werden.
                    </WhiteFormSmallTitle>

                </Grid>
                <Grid item mb={1}>
                    <a style={{ textDecoration: 'none' }}
                        href='https://www.sva-ag.ch/sites/default/files/media/document/Steuerveranlagung%20Satzbestimmendes%20EK-bearbeitet.pdf'
                        rel="noreferrer"
                        target='_blank'>
                        <Grid container columnGap={1.5}>
                            <Grid item xs={'auto'}>
                                <Box pt={1}>

                                    <File />
                                </Box>
                            </Grid>
                            <Grid item xs>
                                <WhiteFormSmallTitle style={{ color: "#545454", fontWeight: "300" }}>
                                    Sehen Sie in diesem Beispiel, welche Positionen
                                    <Typography variant='span' color={'#3A546C'} fontWeight={700}> 1 </Typography>
                                    und
                                    <Typography variant='span' color={'#3A546C'} fontWeight={700}> 2</Typography>.
                                    Sie aus Ihrer Steuerveranlagung 2021 eintragen müssen.
                                </WhiteFormSmallTitle>
                            </Grid>
                        </Grid>
                    </a>
                </Grid>
                <Grid item mb={4}>
                    <WhiteFormLabel>Steuerbares Einkommen (Satz) <Typography variant='span' color={'#3A546C'} fontWeight={700}> 1 </Typography></WhiteFormLabel>
                    <NumericFormat
                        placeholder="CHF"
                        value={formData.Satz1}
                        customInput={WhiteFormInput}
                        thousandSeparator="'"
                        required
                        name='Satz1'
                        onValueChange={(values) => {
                            updateFormData({ Satz1: values.floatValue })
                        }}

                    />

                </Grid>
                <Grid item mb={4}>
                    <WhiteFormLabel>Steuerbares Einkommen (Satz) <Typography variant='span' color={'#3A546C'} fontWeight={700}> 1 </Typography></WhiteFormLabel>

                    <NumericFormat
                        placeholder="CHF"
                        value={formData.Satz2}
                        customInput={WhiteFormInput}
                        thousandSeparator="'"
                        required
                        name='Satz2'
                        onValueChange={(values) => {
                            updateFormData({ Satz2: values.floatValue })
                        }}

                    />
                </Grid>
                <Grid item mb={4}>
                    <WhiteFormLabel style={{ color: '#3A546C', fontWeight: "800" }}>Aufrechnungen</WhiteFormLabel>
                    <a style={{ textDecoration: 'none' }}
                        href='https://www.sva-ag.ch/sites/default/files/media/document/Steuerveranlagung%20Aufrechnungen_0.pdf'
                        rel="noreferrer"
                        target='_blank'>
                        <Grid container columnGap={1.5}>
                            <Grid item xs={'auto'}>
                                <File />
                            </Grid>
                            <Grid item xs>
                                <WhiteFormSmallTitle style={{ color: '#545454', fontWeight: "400" }}>
                                    Beispiel Aufrechnungsposition
                                </WhiteFormSmallTitle>
                            </Grid>
                        </Grid>
                    </a>
                    <Grid container flexDirection={'column'} rowGap={1}>
                        <Grid item xs>

                            <NumericFormat
                                placeholder="+ Kleinverdienerabzug"
                                value={formData.Kleinverdienerabzug}
                                customInput={WhiteFormInput}
                                thousandSeparator="'"
                                required
                                name='Kleinverdienerabzug'
                                onValueChange={(values) => {
                                    updateFormData({ Kleinverdienerabzug: values.floatValue })
                                }}

                            />
                        </Grid>
                        <Grid item xs>

                            <NumericFormat
                                placeholder="+ Liegenschaftsunterhaltskosten"
                                value={formData.Liegenschaftsunterhaltskosten}
                                customInput={WhiteFormInput}
                                thousandSeparator="'"
                                required
                                name='Liegenschaftsunterhaltskosten'
                                onValueChange={(values) => {
                                    updateFormData({ Liegenschaftsunterhaltskosten: values.floatValue })
                                }}

                            />
                        </Grid>
                        <Grid item xs>

                            <NumericFormat
                                placeholder="+ Beiträge Säule 3a"
                                value={formData.Säule3a}
                                customInput={WhiteFormInput}
                                thousandSeparator="'"
                                required
                                name='Säule3a'
                                onValueChange={(values) => {
                                    updateFormData({ Säule3a: values.floatValue })
                                }}

                            />
                        </Grid>
                        <Grid item xs>
                            <NumericFormat
                                placeholder="+ Freiwillige Zuwendungen"
                                value={formData.Freiwillige}
                                customInput={WhiteFormInput}
                                thousandSeparator="'"
                                required
                                name='Freiwillige'
                                onValueChange={(values) => {
                                    updateFormData({ Freiwillige: values.floatValue })
                                }}

                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container alignItems={'center'} rowGap={1}>
                    <Grid item xs>
                        <WhiteFormSmallTitle style={{ color: "#333E48", fontWeight: "300", maxWidth: "250px" }}>
                            Ihr bereinigtes steuerbares Einkommen
                            20% Ihres steuerbaren Vermögens
                        </WhiteFormSmallTitle>
                    </Grid>
                    <Grid item xs={'auto'}>
                        <WhiteFormLabel color={'#545454'}>
                            {
                                ((formData.Satz1 || 0)
                                    + (formData.Kleinverdienerabzug || 0)
                                    + (formData.Liegenschaftsunterhaltskosten || 0)
                                    + (formData.Säule3a || 0)
                                    + (formData.Freiwillige || 0)).toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })
                                // ? (formData.Satz1
                                //     + formData.Kleinverdienerabzug
                                //     + formData.Liegenschaftsunterhaltskosten
                                //     + formData.Säule3a
                                //     + formData.Freiwillige).toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })

                                // : (0).toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })
                            }
                        </WhiteFormLabel>
                        <WhiteFormLabel color={'#545454'}>
                            {((formData.Satz2 * 0.2 || 0)).toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })}
                        </WhiteFormLabel>
                    </Grid>

                </Grid>

                <Box width={'100%'} height={'1px'} bgcolor={'#E4E4E4'} mb={3} />
                <Grid container alignItems={'center'} rowGap={1}>
                    <Grid item xs>
                        <WhiteFormLabel color={'#545454'}>
                            Total Einkommen
                        </WhiteFormLabel>
                    </Grid>
                    <Grid item xs={'auto'}>
                        <WhiteFormLabel color={'#545454'}>
                            {
                                ((formData.Satz1 || 0)
                                    + (formData.Kleinverdienerabzug || 0)
                                    + (formData.Liegenschaftsunterhaltskosten || 0)
                                    + (formData.Säule3a || 0)
                                    + (formData.Freiwillige || 0)
                                    + (formData.Satz2 * 0.2 || 0)).toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })
                            }
                        </WhiteFormLabel>
                    </Grid>
                </Grid>
                <Box width={'100%'} height={'1px'} bgcolor={'#E4E4E4'} mt={'8px'} />
                <Grid item mt={{ xs: 2, md: 5 }}>
                    <FormButton>
                        WEITER
                    </FormButton>
                </Grid>
            </Grid>
        </form>
    )
}

export default Step2Calculation