import { Box, FormLabel, Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import FormInput from './FormInput'
import FormButton from './FormButton'
import { useMultiStepFormContext } from '../context/MultiStepForm/MultiStepFormContext'
import FormSelect from './FormSelect'
import { useNavigate } from 'react-router-dom';
import { cities, krankenkasses } from '../consts/index'

const WrapperForm = styled.form`
    width: 100%;
    max-width: 600px;
    padding-inline: 45px;
    padding-block: 35px;
    background-color:#fff;
    box-shadow: 0px 4px 15.2px 0px #8E8E8E40;
    border-radius: 8px;
    margin-bottom: 0px;
    font-family: 'Mulish', sans-serif !important;
    @media (max-width: 899.98px) {
        padding-inline: 1rem;
        margin-bottom: 1.5rem;
        max-width: none;
    }

    @media (max-width: 599.98px) {
        padding-inline: 0rem;
        margin-bottom: 1.5rem;
        max-width: none;
        box-shadow:none;
        background-color:transaprent;
        border:none;
    }
`

function BlueForm() {
    const { formData, handleInputChange } = useMultiStepFormContext()
    const navigate = useNavigate();

    const HandleFormSubmit = (e) => {
        e.preventDefault()

        navigate('/form');
    }

    return (
        <WrapperForm onSubmit={HandleFormSubmit}>
            <Grid container rowGap={2}>
                <Grid item xs={12}>
                    <FormLabel>Full Name<span style={{ color: "#EF3061" }}>*</span></FormLabel>
                    <FormInput value={formData.fullName} name='fullName' onChange={e => { handleInputChange(e) }} placeholder='Full Name' type='text' required />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Telefonnummer<span style={{ color: "#EF3061" }}>*</span></FormLabel>
                    <Box position={'relative'} alignItems={'center'}>
                        <Box fontSize={'16px'} lineHeight={0} position={'absolute'} top={'31px'} left={'20px'} color={'#757575'}>
                            +41
                        </Box>
                        <FormInput
                            maxLength={9}
                            minLength={9}
                            value={formData.tel}
                            name='tel'
                            onChange={e => { handleInputChange(e) }}
                            placeholder='Telefonnummer'
                            type='tel' required
                            style={{ paddingLeft: '50px' }}
                            pattern="^(?:[\.\-\s]?(21|22|24|26|27|31|32|33|34|41|43|44|51|52|55|56|58|61|62|71|74|76|77|78|79|81|91))(?:[\.\-\s]?\d\d\d)(?:[\.\-\s]?\d\d){2}$"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Krankenkasse<span style={{ color: "#EF3061" }}>*</span></FormLabel>
                    <FormSelect value={formData.krankenkasse || ''} name='krankenkasse' onChange={e => { handleInputChange(e) }} placeholder='Krankenkasse' type='text' required>
                        <option value={''} disabled hidden >Krankenkasse</option>

                        {krankenkasses.map((krankekasse) => (
                            <option key={krankekasse} value={krankekasse}>{krankekasse}</option>
                        ))}
                    </FormSelect>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>City<span style={{ color: "#EF3061" }}>*</span></FormLabel>
                    <FormSelect value={formData.kanton} name='kanton' onChange={e => { handleInputChange(e) }} placeholder='Aragau' type='text' required >
                        {cities.map((city) => (
                            <option key={city} value={city}>{city}</option>
                        ))}
                    </FormSelect>
                </Grid>
                <Grid item xs={12}>
                    <FormButton>
                        LOSLEGEN
                    </FormButton>
                </Grid>
            </Grid>
        </WrapperForm>
    )
}

export default BlueForm