import React from 'react'
import HeroLayout from '../layouts/Home/HeroLayout'
import SectionBreak from '../components/SectionBreak'
import Info from '../layouts/Home/Info'
import Footer from '../layouts/Home/Footer'
import Navbar from '../layouts/Home/Navbar'
import useWindowDimensions from '../hooks/getWindowDimensions'


function Home() {

    // const { width } = useWindowDimensions()

    return (
        < >
            {/* <div style={{ display: width < 899.99 ? "none" : "block" }} >
                <Navbar />
            </div> */}
            <SectionBreak />
            <HeroLayout />
            <SectionBreak />
            <Info />
            <SectionBreak style={{ paddingTop: '50px' }} />
            <Footer />
        </>
    )
}

export default Home