import React from 'react'
import BlueForm from '../../components/BlueForm'
// import HeroTitle from '../../components/HeroTitle'
import HeroSubtitle from '../../components/HeroSubtitle'
import Container from '../../components/Container'
// import styled from 'styled-components'
// import BackgroundImage from '../../assets/images/landingHeroBg.webp'
import { Box, Grid } from '@mui/material'
import HomeLabel from '../../components/HomeLabel'
import HomeSecondTitle from '../../components/HomeSecondTitle'
import FormButton from '../../components/FormButton'
import { ReactComponent as Info } from '../../assets/svgs/info.svg'
import InfoText from '../../components/InfoText'
import useWindowDimensions from '../../hooks/getWindowDimensions'
import PrimaryButton from '../../components/PrimaryButton'

// const BgImageWrapper = styled(Box)`
//     background-image: url(${BackgroundImage});
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position-x: center;
//     background-position-y: 20%;
//     display: flex;
    
//     @media (max-width: 899.98px) {
//         background-position-x: 35%; 
//     }

// `

function HeroLayout() {

    const { width } = useWindowDimensions()

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingTop: width > 899.99 ? "50px" : "0px" }}>
            <Grid item>
                <Container>
                    <Grid container alignItems={"start"} columnSpacing={{ sm: 3, md: 9 }} rowGap={3} >
                        <Grid item xs={12} sm={6}>
                            <Box width={{ sm: '280px', md: '420px' }} >
                                <HomeLabel>SANLAM INDIE</HomeLabel>
                                <HomeSecondTitle style={{ fontWeight: "400" }}>Prämienverbilligung</HomeSecondTitle>
                                <HeroSubtitle color={'#333E48'}>
                                    Der Kanton Aargau gewährt seinen Einwohnerinnen und
                                    Einwohnern in bescheidenen wirtschaftlichen Verhältnissen Verbilligungsbeiträge
                                    für die obligatorische Krankenpflegeversicherung.
                                </HeroSubtitle>

                                <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: "row" }, columnGap: 1, rowGap: 1, paddingTop: "20px" }}>
                                    <PrimaryButton>Learn more</PrimaryButton>
                                    <PrimaryButton style={{ color: "#0D2C54", backgroundColor: "#fff" }} >Let us contact you</PrimaryButton>
                                </Box>
                                {/* <div style={{ display: "flex", flexDirection: "row", columnGap: 5, paddingTop: '5px', alignItems: "center" }}>
                                    <Info />
                                    <InfoText>Starting from R100 pm</InfoText>
                                </div> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <BlueForm />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

            </Grid>

        </div >
    )
}

export default HeroLayout