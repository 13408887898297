import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
    width: 100%;
    height: 60px;
    border: 1px solid #F2F2F2;
    color:  #757575;
    font-family:'Mulish', sans-serif !important;
    font-weight: 400;
    background-color: #F2F2F2;
    padding-inline: 20px;
    border-radius: 7px;
    outline: none;
    box-sizing: border-box;
    font-size: 16px;

    &::placeholder {
        color: #757575;
        font-weight: 500;
        font-size: 16px;
    }
`

function FormInput({ ...rest }) {
    return (
        <Input
            {...rest}
        />
    )
}

export default FormInput