import { Typography } from '@mui/material'
import React from 'react'

function HomeSecondTitle({ children }) {
    return (
        <Typography variant="h1" paddingBottom={'0.75rem'} color={'#002345'} fontWeight={400} sx={{
            fontSize: {
                xs: '36px',
                sm: '28px',
                md: '32px',
                lg: '52px',
            },
            fontFamily: 'Times Ten, sans-serif',
            letterSpacing:'-1.6px'
        }}>
            {children}
        </Typography>
    )
}

export default HomeSecondTitle