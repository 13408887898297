import { Box } from '@mui/material'
import React from 'react'

function SectionBreak({ ...rest }) {
    return (
        <Box
            {...rest}
            paddingTop={{xs: '30px', md: '150px'}}
        ></Box>
    )
}

export default SectionBreak