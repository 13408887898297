import React from 'react'
import Container from '../../components/Container'
import { Box, Typography } from '@mui/material'

function Footer() {
    return (
        <Container>
            <Box paddingInline={{ md: '5rem' }}>

                <Box width={'100%'} height={'1px'} bgcolor={'#8B8B8B4D'} mb={2}></Box>
                <Typography color={'#8B8B8B'} fontSize={{ xs: '12px', md: '16px' }} mb={2} textAlign={'center'} fontFamily={'Poppins, sans-serif'}>
                    Copyright © 2024 Kutiza LLC | All Rights Reserved
                </Typography>
            </Box>
        </Container>
    )
}

export default Footer