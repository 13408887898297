export const krankenkasses = [
    "CSS",
    "Helsana",
    "Swica",
    "Assura",
    "Sanitas",
    "Visana",
    "Galenos",
    "Sympany",
    "KPT",
    "Aquilana",
    "Intras",
    "ÖKK",
    "Progrès",
    "Swisscare",
    "Compact",
    "EGK",
    "Groupe Mutuel",
    "Mutuel Assurance",
    "Philos",
    "Sana24",
    "SLKK",
    "Supra",
    "Visperterminen",
    "Vivao Sympany",
    "Arcosana",
    "Atupri",
    "Avanex",
    "CPT",
    "Easy Sana",
    "Glarner",
    "Innova",
    "Kolping",
    "Lumneziana",
    "Mobi24",
];

export const cities = [
    "Aargau",
    "Appenzell Ausserrhoden",
    "Appenzell Innerrhoden",
    "Basel-Landschaft",
    "Basel-Stadt",
    "Bern",
    "Fribourg",
    "Geneva",
    "Glarus",
    "Graubünden",
    "Jura",
    "Lucerne",
    "Neuchâtel",
    "Nidwalden",
    "Obwalden",
    "Schaffhausen",
    "Schwyz",
    "Solothurn",
    "St. Gallen",
    "Ticino",
    "Thurgau",
    "Uri",
    "Valais",
    "Vaud",
    "Zug",
    "Zurich"
]