import { Typography } from '@mui/material'
import React from 'react'

function HeroSubtitle({ children, color = '#fff' }) {
    return (
        <Typography variant="h1" color={color} fontWeight={300} sx={{
            fontSize: {
                xs: '16px',
                lg: '18px',
            },
            lineHeight: {
                xs: '24px',
                lg: '27px'
            }
        }}>
            {children}
        </Typography>
    )
}

export default HeroSubtitle