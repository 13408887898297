
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MultiStepFormProvider from './context/MultiStepForm/MultiStepFormProvider';
import Home from './pages/Home';
import Form from './pages/Form';

function App() {
  return (
    <MultiStepFormProvider>

      <BrowserRouter>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/form" element={<Form />} />
        </Routes>

      </BrowserRouter>
    </MultiStepFormProvider>
  );
}

export default App;
